<template>
	<div class="wrapper">
		<base-font variant="heading" marginReset>
			{{translations.client_data.edit_data}}
		</base-font>
		<form class="form" v-if="form">
			<info-box v-if="!!currentPageView.status.code" :status="currentPageView.status.code">
				{{currentPageView.status.message}}
			</info-box>
			<div class="form-inner-wrapper">
				<input-template :input="form.inputs.firstName"  primary/>
				<input-template :input="form.inputs.lastName"  primary/>
				<input-template :input="form.inputs.companyName"  primary/>
				<input-template :input="form.inputs.streetAndBuildingNumber"  primary/>
				<input-template :input="form.inputs.city"  primary/>
				<input-template :input="form.inputs.postalCode" primary/>
				<input-template :input="form.inputs.phoneNumber"  primary/>
				<input-template :input="form.inputs.email"  primary/>
			</div>
			<button-submit @click="currentPageView.updateClientData()"  :loading="currentPageView.processing" type="secondary" :rightSlot="true">
				<base-font color="white" tag="span">{{translations.client_data.change}}</base-font>
			</button-submit>
		</form>
	</div>
</template>
<script>
import ButtonSubmit from '~/website/front/components/molecules/ButtonSubmit'
import { InputTemplate } from '@f/core/forms/inputs'
import InfoBox from '~/authorization/front/components/InfoBox'

export default {
	components: {
		ButtonSubmit,
		InputTemplate,
		InfoBox
	},
	computed: {
		page () {
			return this.$app.page
		},
		currentPageView () {
			return this.page.view
		},
		form () {
			return this.currentPageView.form
		},
		translations () {
			return this.$app.translator.get('client-profile')
		}
	}
}
</script>
<style lang="scss" scoped>
	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.form {
			margin: 3rem 0;
		}

		.info-box {
			margin-top: 20px;
		}
		::v-deep .btn {
			width: 100%;
			margin-bottom: 0;

			@include media-breakpoint-up(lg) {
				width: 35%
			}
			@include media-breakpoint-up(xl) {
				width: 25%;
			}
		}
	}
	.form-inner-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	::v-deep .field-wrapper {
		width: 100%;
		margin-right: 0!important;

		@include media-breakpoint-up(lg) {
			width: 48%;
		}
	}
</style>
